import React from "react";
import "./css/Blog.css";
import { AddtoBlog } from "./utils/AddtoBlog";
const Blog = () => {
  return (
    <div className="container-expander">
      {/* <h1 className='title-Blog'>Blog</h1> */}
      <AddtoBlog />
    </div>
  );
};
export default Blog;
