import React from "react";

export const AddtoBlog = () => {
  return (
    <div>
      {/* Here we are going to add to the blog */}
      <h1 className="title-entries">First entry</h1>
      <h2 className="date-entries">Saturday, August 24</h2>
      <p className="paragraph-text">
        Hello, My name is Jesus Lopez!!!<br></br>
        This is the first entry of this blog for my personal webpage, I was
        really looking to have a personal blog that anyone could read.
      </p>
    </div>
  );
};
