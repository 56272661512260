import React from "react";
import "../css/Experience.css";

export const AddtoExtracurricularActivities = () => {
  return (
    <div>
      {/* Here we are going to add to the blog */}
      <h2 className="title-experience">
        President of the ACM Student chapter at UTEP (January 2024 - May 2025)
      </h2>
      <h3 className="subtitle-experience">
        ACM (Association for Computing Machinery)
      </h3>
      <p className="text-experience">
        {/* Description */}
        <ul className="text-experience">
          <li>
            I conducted workshops every week to help students create projects
            for their resume.
          </li>
        </ul>
      </p>
      {/* ---------------------------------------- */}
      <h2 className="title-experience">President of the ACM Student chapter</h2>
      <p className="text-experience">
        {/* Description */}
        hello
      </p>
      <br></br>
    </div>
  );
};
