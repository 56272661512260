import React from "react";
import "../css/Experience.css";
export const AddtoExperience = () => {
  return (
    <div>
      {/* Here we are going to add to the blog */}
      <h2 className="title-experience">
        Cybersecurity Camp Coach Lead (June 2024 - July 2024)
      </h2>
      <h3 className="subtitle-experience">
        Air Force Association (AFA) CyberPatriot
      </h3>
      <p className="text-experience">
        {/* Description */}
        hello
      </p>
      {/* ---------------------------------------- */}
      <h2 className="title-experience">
        Artificial Intelligence Camp Instructor (2024)
      </h2>
      <p className="text-experience">
        {/* Description */}
        hello
      </p>
      {/* ---------------------------------------- */}
      <h2 className="title-experience">
        Local Research Experience for Undergraduate (LREU) (Jan 2024 - May 2024)
      </h2>
      <h3 className="subtitle-experience">
        Computing Alliance of Hispanic-Serving Institutions (CAHSI)
      </h3>
      <ul className="text-experience">
        <li>
          Conducted mentored research as part of the CAHSI Local REU program,
          and engaged in training to strengthen my computing, communication, and
          professional skills. Created a research plan, maintained a journal to
          report on research progress, and created a research poster to
          disseminate my research results.
        </li>
        <li>Developed an algorithm that </li>
      </ul>

      {/* ---------------------------------------- */}
      <h2 className="title-experience">
        Teaching Assitant Discover AI Program (Sep 2023 - Dec 2023){" "}
      </h2>
      <h3 className="subtitle-experience">AI4ALL</h3>
      <ul className="text-experience">
        <li>
          Discover AI is a program that introduces college students to learn and
          choose AI as a job pathway.
        </li>
        <li>
          Facilitating discussions and proctored laboratories in supervised
          learning, data analytics, and data cleanse to 30+ students resulting
          in the successful completion of the Discover AI program.
        </li>
      </ul>

      {/* ---------------------------------------- */}
      <h2 className="title-experience">
        Cybersecurity Camp Coach (July 2023 - July 2023)
      </h2>
      <h3 className="subtitle-experience">
        Air Force Association (AFA) CyberPatriot
      </h3>
      <ul className="text-experience">
        <li>
          Participated as staff on the Air Force Association (AFA) hackathon
          regarding topics as Cisco, Windows server, and Ubuntu training.
        </li>
        <li>
          Trained 45 selected high school students through a bi-weekly session
          in cyber hygiene, security tools, networking, and Linux encouraging
          their participation in the AFA Hackathon.
        </li>
        <li>The duration of the AFA Cybercamp was 1 month. </li>
      </ul>
      {/* ---------------------------------------- */}
      <h2 className="title-experience">
        Computer Science Peerleader (2021-2022)
      </h2>
      <h3 className="subtitle-experience">El Paso Community College (EPCC)</h3>
      {/* Description */}
      <ul className="text-experience">
        <li>
          Proctored programming laboratories for online and in-person sessions
          for more than 250 students.
        </li>
        <li>
          Managed the update of nearly 45 lab activities by analyzing behavioral
          and performance data using tools in google suite.
        </li>
        <li>
          Designed laboratories in Java for 3 computer science classes following
          the College Board’s Advance Placement (AP) program and Association of
          Computer Machinery (ACM) standards.
        </li>
      </ul>
      <br></br>
    </div>
  );
};
