import React from "react";

const Project3 = () => {
  return (
    <div>
      <h1>Programming hashmap from 0</h1>
    </div>
  );
};

export default Project3;
