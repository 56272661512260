import React from "react";
import CardBase from "./CardBase";
import cardData from "./cardData";

export const renderCards = () => {
  return cardData.map((card, index) => (
    <CardBase
      key={index}
      title={card.title}
      content={card.content}
      urltopage={card.urltopage}
    />
  ));
};
