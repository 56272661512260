import React from "react";
import "./project_css/p4.css";
import "./project_css/global.css";

const Project4 = () => {
  return (
    <div className="container">
      <div className="container-text">
        <h1 className="title-p">Terminal Mini Game</h1>
        <h2 className="subtitle-p">Language: Java</h2>
        {/* <br /> */}
        <hr className="divider" />
        <p className="text">
          Welcome students to this lab, here you are going to learn how to
          create a mini dungeon game that runs on terminal. To start working in
          this project you do not have to be a expert in java, here you will
          find a guide on how to do everything and implement the code. it will
          be also on my personal web page.
        </p>

        <h2 className="subtitle-p">Learning Outcomes</h2>
        <p className="text">
          <ul>
            <li>
              Understand how to parse CSV file and I/O operations with it.
              <li>Create systems to manage gamestates, such as win/loss.</li>
              <li>Create a system to manage random battle systems.</li>
              <li>
                Implement Basic functionalities to the game such as walking.
              </li>
              <li>
                Utilize data structures to efficiently manage game elements.
              </li>
            </li>
          </ul>
        </p>

        {/* this is the button for the repo */}
        <div className="center">
          <button className="button-container">
            <a
              className="link"
              href="https://github.com/NotSamus/ACM-UTEP-miniprojects/tree/master/1-terminal_game"
            >
              Repository
            </a>
          </button>
        </div>

        <p className="text">See you and Happy Coding!!!</p>
      </div>
    </div>
  );
};

export default Project4;
