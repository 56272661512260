import React from "react";
import { Card } from "./Card";
import "../css/AboutMe.css";
export const AboutMe = () => {
  return (
    <div className="cont">
      <div>
        <p className="mini-title1">
          <br />
          Hello Everyone,
          <br /> My name is <strong>Jesus Lopez</strong>!!
        </p>
        <p className="text-aboutme">
          I am a Computer Science student at The University of Texas at El Paso.
          Currently I am in my Senior year and I am pursuing the MS in Computer
          Science and Looking forward to also do my PHD and Computer Science.
          <br />
          <br />
          <strong>Fun fact:</strong> I have a cat named Meow and I love to learn
          difficult things.
        </p>
      </div>

      <div className="center-1">
        <Card />
      </div>
    </div>
  );
};
