import React from "react";
import "../css/Experience.css";
export const AddtoEducation = () => {
  return (
    <div>
      <h2 className="education-test">The University of Texas at El Paso</h2>
      <h2 className="education-sub">
        Bachelors in Computer Science <br></br>Minor in Mathematics
      </h2>
      <br></br>
      <h2 className="education-test">El Paso Comunity College</h2>
      <h2 className="education-sub">
        Associates in Art <br></br>
      </h2>
      <br></br>
      <br></br>
    </div>
  );
};
