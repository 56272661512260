import React from "react";
import "./css/Resources.css";
const Resources = () => {
  return (
    <div className="container-expander1 ">
      <h1 className="title-Resources">Resources</h1>
    </div>
  );
};

export default Resources;
