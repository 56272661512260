import React from "react";
import "./css/Navbar.css";
const Navbar = () => {
  return (
    <div>
      <div className="header">
        <a href="#/" className="logo">
          NotSamus
        </a>
        <div className="navbar">
          <a href="#/">Home</a>
          <a href="#/Experience">About me</a>
          <a href="#/Projects">Projects</a>
          <a href="#/Blog">Blog</a>
          <a href="#/Resources">Resources</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
