const cardData = [
  {
    title: "Terminal Mini Game",
    content: "This is a mini tutorial to create a game in java",
    urltopage: "#/Project_files/p4",
  },
  {
    title: "Qr generator in Python",
    content:
      "Build a QR code generator with Python using qrcode and tkinter. This quick tutorial guides you through creating a simple app to generate and download custom QR codes. Perfect for beginners!",
    urltopage: "#/Project_files/p1",
  },
  {
    title: "Programming hashmap from 0",
    content: "Second card",
    urltopage: "#/Project_files/p2",
  },
  {
    title: "tkinter Tutorial Python",
    content: "Third card",
    urltopage: "#/Project_files/p3",
  },
];

export default cardData;
