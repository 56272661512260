import React from "react";
import { IoLogoGithub } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io5";
import "../css/Card.css";

export const Card = () => {
  // let icons = {
  //   github: <IoLogoGithub />,
  //   linkedin: <IoLogoLinkedin />,
  // };
  return (
    <div className="card">
      <img
        className="card-picture"
        src="https://i.imgur.com/pUCOV0P.jpeg"
        alt="profile_picture"
      />
      <div>
        <h2 className="card-title">Jesus Lopez</h2>
        <p className="card-text">Computer Science Student</p>
      </div>
      <a href="https://github.com/NotSamus" rel="noopener noreferrer">
        <IoLogoGithub className="github" size={42} />
      </a>
      <a
        href="https://www.linkedin.com/in/jlopez126/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IoLogoLinkedin className="linkedin" size={42} />
      </a>
    </div>
  );
};
