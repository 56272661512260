// Card.js
import React from "react";
import "../css/TCard.css";

const CardBase = ({ title, content, urltopage }) => {
  return (
    <div style={styles.Card}>
      <div style={styles.content}>
        <h2 className="card-title1">
          <a className="title-click" href={urltopage}>
            {title}
          </a>
        </h2>
        <p>
          {content} <a href={urltopage}> Read More..</a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  Card: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px",
    margin: "16px",
    height: "170px",
    width: "75%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "100%",
    borderRadius: "8px",
  },
  content: {
    marginTop: "16px",
  },
};

export default CardBase;
