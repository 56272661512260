import React from "react";
import "./css/Experience.css";
import { AddtoExperience } from "./utils/AddtoExperience";
import { AddtoEducation } from "./utils/AddtoEducation";
import { AddtoExtracurricularActivities } from "./utils/AddToExtracurricularActivities";
// import { AboutMe } from "./utils/AboutMe";
const Experience = () => {
  return (
    <div>
      <h1 className="title-main">About Me</h1>
      {/* here is the about me */}
      <p className="text-experience">
        I am a passionate CS major with a strong foundation of algorithms, data
        structures, and software development. I am currently
      </p>
      {/* end */}
      <h1 className="title-main">Experience</h1>
      <AddtoExperience />
      <h1 className="title-main">Education</h1>
      <AddtoEducation />
      <h1 className="title-main">Extracurricular Activities</h1>
      <AddtoExtracurricularActivities />
    </div>
  );
};

export default Experience;
