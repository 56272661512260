import React from "react";
import "./css/Home.css";
import { AboutMe } from "./utils/AboutMe";

function Home() {
  return (
    <div className="main">
      <AboutMe />
      {/* <footer>notsamus</footer> */}
    </div>
  );
}

export default Home;
