import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
// Navbar
import Navbar from "./Components/Navbar";
import Experience from "./Components/Experience";
import Resources from "./Components/Resources";
import Home from "./Components/Home";
import Projects from "./Components/Projects";
import Blog from "./Components/Blog";
// Projects
import Project1 from "./Components/Project_files/Project1";
import Project2 from "./Components/Project_files/Project2";
import Project3 from "./Components/Project_files/Project3";
import Project4 from "./Components/Project_files/Project4";
function App() {
  return (
    <Router basename="/">
      <Navbar />
      <Routes>
        {/* This is for the navbar */}
        <Route path="/" element={<Home />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/Resources" element={<Resources />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        {/* This is for the Projects */}
        <Route path="/Project_files/p1" element={<Project1 />} />
        <Route path="/Project_files/p2" element={<Project2 />} />
        <Route path="/Project_files/p3" element={<Project3 />} />
        <Route path="/Project_files/p4" element={<Project4 />} />
      </Routes>
    </Router>
  );
}

export default App;
