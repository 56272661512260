import React from "react";
import "./css/Projects.css";
import { renderCards } from "./utils/renderCards";
const Projects = () => {
  return (
    <div>
      <h1 className="title-Projects">Projects</h1>
      <div className="card-container">{renderCards()}</div>
    </div>
  );
};

export default Projects;
