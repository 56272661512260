import React from "react";

const Project2 = () => {
  return (
    <div>
      <h1>Hello world2</h1>
    </div>
  );
};

export default Project2;
